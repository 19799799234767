
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import DateHelpers from "@/mixins/DateHelpers";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";
import PoliticasDePrivacidad from "@/components/PoliticasDePrivacidad.vue";

interface registerData {
  name: string;
  father_last_name: string;
  mother_last_name: string;
  nationality: any;
  document_type: string;
  document_id: string;
  district: any;
  street: string;
  street_number: string;
  hint: string;
  email: string;
  phone: string;
  password: string;
  password_confirm: string;
  date_birth: string | null;
  genre: string | number;
}

@Component({
  components: {
    PoliticasDePrivacidad
  }
})
export default class RegisterForm extends Mixins(DateHelpers, FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  politicasFooter: Boolean = true;
  terminos = "no";
  politicas: Boolean = false;
  options_district: any = comunas;
  options_nationality: any = countries;
  options_street: any[] = [];
  street_loading = false;
  street_search_empty = false;
  chilean = true;
  streets: any[] = [];
  comuna_municipalidad: any = {
    id: 0,
    id_region: 0,
    nombre: ""
  };

  protected formData: registerData = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    nationality: {
      value: 47,
      code: "CL",
      country_en: "Chile",
      country_es: "Chile",
      label: "Chilena"
    },
    document_type: "1",
    document_id: "",
    district: "",
    street: "",
    street_number: "",
    hint: "",
    email: "",
    phone: "",
    password: "",
    password_confirm: "",
    date_birth: "",
    genre: ""
  };

  private fechaHoy: any = new Date();
  private formattedFecha: string | null = "";

  options_genre = [
    { label: "Femenino", value: "1" },
    { label: "Masculino", value: "2" },
    { label: "Otro", value: "3" },
    { label: "Prefiero no decirlo", value: "4" },
    { label: "Sin información", value: "5" }
  ];

  my_locale = {
    days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    firstDayOfWeek: 7
  };

  @Watch("formData.phone")
  private watchPhone(newValue: any) {
    let formated = newValue.replace(" ", "");
    formated = formated.replace(" ", "");
    formated = formated.replace(".", "");
    formated = formated.replace("-", "");
    formated = formated.replace("+", "");
    formated = formated.replace(",", "");
    this.formData.phone = formated;
  }

  private registerAttemp(): void {
    if (this.formattedFecha != "" && this.formattedFecha != null) {
      const { formattedFecha } = this;
      if (!/^\d{2}-\d{2}-\d{4}$/.test(formattedFecha)) {
        this.$q.notify({
          message:
            "Fecha incompleta. Por favor, ingrese una fecha en el formato dd-mm-aaaa",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        return;
      } else if (!this.checkDate(formattedFecha)) {
        this.$q.notify({
          message: "Fecha inválida. Por favor, escriba una fecha válida",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        return;
      } else if (
        Date.parse(this.fechaHoy as any) <
        Date.parse(this.birthDate(formattedFecha))
      ) {
        this.$q.notify({
          message: "La fecha de nacimiento debe ser menor a la fecha actual",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        return;
      }
    } else {
      this.formattedFecha = null;
    }
    if (this.terminos == "no" || !this.terminos) {
      this.$q.notify({
        color: "negative",
        message:
          "Para continuar, Por favor, acepte las Políticas de Privacidad.",
        icon: "report_problem",
        position: "top",
        timeout: 5000
      });
      return;
    }
    this.$q.loading.show();
    this.$axios
      .post("personas/registro", {
        nombre: this.formData.name,
        apellido_paterno: this.formData.father_last_name,
        apellido_materno: this.formData.mother_last_name,
        email: this.formData.email,
        password: this.formData.password,
        password_confirmacion: this.formData.password_confirm,
        telefono: this.formData.phone,
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id,
        id_pais: this.formData.nationality.value,
        fecha_nacimiento: this.formattedFecha,
        genero: Number(this.formData.genre),
        //borrar una vez que se arregle el endpoint
        // kkkk
        tipo: 1,
        direccion: {
          id_comuna: this.formData.district.value,
          calle: this.formData.street,
          numero: this.formData.street_number,
          aclaratoria: this.formData.hint,
          //borrar una vez se arregle el endpoint
          // kkkk
          tipo: 0
        }
      })
      .then(response => {
        this.$q.notify({
          message:
            "Te hemos enviado un correo de verificación a " +
            this.formData.email,
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.$store.dispatch("login", response.data);
        this.redirectHome();
      })
      .catch(error => {
        let message = "Error";
        switch (error.response.data.error.message) {
          case "duplicate_email_error":
            message = "El correo ya ha sido utilizado en el sistema";
            break;
          case "duplicate_email":
            message = "El correo ya ha sido utilizado en el sistema";
            break;
          case "duplicate_rut":
            message = "El RUT ya ha sido utilizado en el sistema";
            break;
          case "ER_DUP_ENTRY":
            message = "El RUT ya ha sido utilizado en el sistema";
            break;
          case "auth/email-already-exists":
            message = "El correo ya ha sido utilizado en el sistema";
            break;
          case "auth/invalid-email":
            message = this.$t("validation.invalid_email") as string;
            break;
          case "auth/operation-not-allowed":
            message = "Operación no permitida";
            break;
          case "auth/invalid-password":
            message = "La contraseña es inválida";
            break;
          case "auth/weak-password":
            message = "La contraseña no es lo suficientemente segura";
            break;
          default:
            message = "Error desconocido";
            break;
        }
        this.$q.notify({
          message: message as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private checkIfChilean() {
    if (this.formData.nationality.value === 47) {
      this.chilean = true;
      this.formData.document_type = "1";
    } else {
      this.chilean = false;
    }
  }

  private limitSince(date: any) {
    return Date.parse(date as any) <= Date.parse(this.fechaHoy as any);
  }

  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_municipalidad.nombre) {
      this.formData.street = "";
    }
  }

  private redirectHome(): void {
    if (this.quick_login) {
      this.$emit("logged");
      return;
    }
    this.$router.push({ name: "welcome" });
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private abortFilterFn() {}

  private filterDistrict(val: string, update: any) {
    if (val === "" || val == undefined) {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      let value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
    });
  }

  private filterNationality(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_nationality = countries;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_nationality = (countries as any).filter((country: any) => {
        let filtered =
          country.label.toLowerCase().indexOf(value) > -1 ||
          country.label === this.formData.nationality.label;
        return filtered;
      });
    });
  }

  private streetDistrictData() {
    this.$axios
      .get("calles", {
        params: {
          filter: {
            where: { estado: 1 },
            fields: ["id", "id_comuna", "nombre", "estado"],
            order: "nombre ASC"
          }
        }
      })
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });

    this.$axios
      .get("comunas/default")
      .then(response => {
        this.comuna_municipalidad = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private mostrarPoliticas() {
    this.politicas = true;
    this.politicasFooter = true;
  }

  private onClosePoliticas(info: any) {
    if (info == true) {
      this.terminos = "si";
      this.politicas = false;
    } else if (info == false) {
      this.terminos = "no";
      this.politicas = false;
    } else {
      this.politicas = false;
    }
  }

  private mounted() {
    this.streetDistrictData();
  }
}
